import 'regenerator-runtime/runtime'
import fetchLastPosts from './wordpress'

const toggleDisplay = (el) => {
  el.classList.toggle("is-active");
  document.querySelector("html").classList.toggle("is-clipped");
};

document.querySelector(".text-field").style.display = "none";

const buttons = document.querySelectorAll("button.popover-trigger");

buttons.forEach((button) => {
  button.addEventListener("click", () => {
    const content = button.parentNode.querySelector(".popover-content");
    const closeBtn = content.querySelector(".close-icon");
    if (content.style.visibility !== "visible") {
      content.style.visibility = "visible";
      content.style.opacity = "1";

      closeBtn.addEventListener("click", function () {
        content.style.visibility = "hidden";
        content.style.opacity = "0";
      });
    } else {
      content.style.visibility = "hidden";
      content.style.opacity = "0";
    }
  });
});

const legalMentions = document.querySelector("#legal");
const modal = document.querySelector("#legal-modal");
const modalCloseBtn = document.querySelector("#modal-close-btn");
legalMentions.addEventListener("click", () => toggleDisplay(modal));
modalCloseBtn.addEventListener("click", () => toggleDisplay(modal));

const computeColumnsSize = (length) => {
  switch (length) {
    case 1:
      return "is-half"
      case 2:
        return "is-two-fifths"
      case 3:
      return "is-one-third"

  }
}
window.onload = async() => {
  const blogSection = document.getElementById('blog')
  const posts = await fetchLastPosts();
  const columnSize = computeColumnsSize(posts.length)

  posts.forEach((post) => {
    const div = document.createElement('div')
    div.classList.add('column', 'post', columnSize)
    div.innerHTML = 
    `<div class="box box-shadow-lift">
      <div class="card-content">
        <div class="media" style="border-top: none;">
          <div class="media-content has-text-centered" style="margin: 0.9rem 0;">
            <p class="title is-4">${post.title.rendered}</p>
            <p class="subtitle has-text-grey is-6">
              ${new Date(post.date).toLocaleDateString()}
            </p>
          </div>
        </div>
        ${post.excerpt.rendered}
        </div>
      </div>`
    const moreLink = div.querySelector('.more-link')
    if (moreLink) {
      moreLink.setAttribute('target', "_blank");
    }
    blogSection.appendChild(div)
  })
}